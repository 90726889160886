import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import HeaderGeneral from "../../../components/HeaderGeneral"
import Titulo1 from "../../../components/Titulo1"
import estudiantes from "../../../assets/imagenes/estudiantes.png"
import Boton from '../../../components/Boton'
import { colorPrimario, fondo } from '../../../components/UI/variables'
import BeneficiosStudent from "../../../components/BeneficiosStudent"
import PiePaginaContacto from "../../../components/PiePaginaContacto"
import Desplegable from "../../../components/Desplegable"
import { UnauthenticatedContextGeneral } from "../../../context/UnauthenticatedContext";
import { getFirestore, collection, onSnapshot, query, where } from "firebase/firestore";
import appFirebase from "../../../firebase";

const StyledDiv=styled.div`
  background-color: ${fondo};
  height: calc(100vh - 88px);
  display:flex;
  flex-direction:column;
  align-items: center;
  padding-top:88px;
`
const StyledImg=styled.img`
  width: 360px;
  height: 336px;
  margin-top: 0px;
`
const TextoBanner=styled(Titulo1)`
  position:absolute;
  transform: translate(0%,50%);
  color: black;
`
const DivBuscar=styled.div`
  padding: 8px;
  position:absolute;
  transform: translate(0%,200%);
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
`
const BotonCallToAction = styled(Boton)`
  background-color: #FFC700;
  color: black;
`
const BotonPropietario=styled(Boton)`
  width:328px;
  background-color:transparent;
  color: black;
  border: 3px solid ${colorPrimario};
  font-weight:500;
  margin: 29px 0px;
  border-radius: 24px;
  transition: background-color 1s ease;
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
`
const HomeStudent = () => {
  const db = getFirestore(appFirebase);
  const {setPropiedadesUnauthenticated} = useContext(UnauthenticatedContextGeneral)
  const [ubicacion, setUbicacion] = useState("");
  const manejarUbicacion = (valor) => {
    setUbicacion(valor);
  };
  const obtenerPropiedadesUbicacion = ()=>{
    const q = query(collection(db, "propiedades"),where("departamento", "==", `${ubicacion}`), where("disponibilidadPropiedad", "==", true))

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const propiedadesFirebase = snapshot.docs.map((doc) => ({
        ...doc.data(),
        idPropiedad: doc.id,
      }));
      setPropiedadesUnauthenticated(propiedadesFirebase);
    });
    return () => unsubscribe();
  }
  
  return (
    <>
        <HeaderGeneral/>
        <StyledDiv>
          <StyledImg src={estudiantes} alt='Grupo de estudiantes contentos buscando habitaciones a traves de sus celulares'/>
          <TextoBanner>Si eres estudiante encuentra tu habitación perfecta y descubre tu propio camino!!! lml</TextoBanner>
          <DivBuscar>
            <Desplegable onSeleccionChange={manejarUbicacion}/>
            <BotonCallToAction onClick={obtenerPropiedadesUbicacion} $large to={`/Propiedades/${ubicacion}`}>Explorar</BotonCallToAction>
          </DivBuscar>
          <BeneficiosStudent/>
          <BotonPropietario to="/HomeOwner">Eres propietario? Arkila con nosotros AQUI</BotonPropietario>
        </StyledDiv>
        {/* <PiePaginaContacto/> */}
    </>
  )
}
export default HomeStudent