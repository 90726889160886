import styled from "styled-components"
import NavigationTab from "../../../components/NavigationTab"
import tarjetaPropiedad1 from "../../../assets/imagenes/imagenesInstructivo/tarjetaPropiedad1.png"
import agregarPropiedad2 from "../../../assets/imagenes/imagenesInstructivo/agregarPropiedad2.png"
import formularioPropiedad3 from "../../../assets/imagenes/imagenesInstructivo/formularioPropiedad3.png"
import formularioPropiedad4 from "../../../assets/imagenes/imagenesInstructivo/formularioPropiedad4.png"
import tarjetaPropiedadEditar4a from "../../../assets/imagenes/imagenesInstructivo/tarjetaPropiedadEditar4a.png"
import { colorPrimario, colorSecundario } from "../../../components/UI/variables"
import registraHabitaciones5 from "../../../assets/imagenes/imagenesInstructivo/registraHabitaciones5.png"
import seleccionaPropiedad6 from "../../../assets/imagenes/imagenesInstructivo/seleccionaPropiedad6.png"
import agregarHabitacion7 from "../../../assets/imagenes/imagenesInstructivo/agregarHabitacion7.png"
import formularioHabitacion8 from "../../../assets/imagenes/imagenesInstructivo/formularioHabitacion8.png"
import formularioHabitacion9 from "../../../assets/imagenes/imagenesInstructivo/formularioHabitacion9.png"
import tarjetaHabitacionEditar9a from "../../../assets/imagenes/imagenesInstructivo/tarjetaHabitacionesEditar9a.png"
import registraInquilinos10 from "../../../assets/imagenes/imagenesInstructivo/registraInquilinos10.png"
import formularioInquilinos11 from "../../../assets/imagenes/imagenesInstructivo/formularioInquilinos11.png"
import formularioBotonRegistrar11a from "../../../assets/imagenes/imagenesInstructivo/formularioBotonRegistrar11a.png"
import botonDesocupar12 from "../../../assets/imagenes/imagenesInstructivo/botonDesocupar12.png"
import controlaPagos13 from "../../../assets/imagenes/imagenesInstructivo/controlaPagos13.png"
import listaControlPagos14 from "../../../assets/imagenes/imagenesInstructivo/listaControlPagos14.png"
import botonRegistrarPago15 from "../../../assets/imagenes/imagenesInstructivo/botonRegistrarPago15.png"
import listaPagado16 from "../../../assets/imagenes/imagenesInstructivo/listaPagado16.png"
import HeaderAuthenticatedOwner from "../../../components/HeaderAuthenticatedOwner";
import { useState } from "react"

const StyledContenedor=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;  
    padding: 16px;
    padding-top: 96px;
    p{
        width: 328px;
        font-weight: 500;
    }   
`
const StyledBoton=styled.button`
    width: 288px;
    border: 1px solid ${(props)=>props.color} ;
    border-radius: 24px;
    padding: 8px;
    margin-bottom: 8px;
    background-color: transparent;  
    transition: font-size ease 0.5s;
    &:hover {
        font-size: 16px;
        font-weight: bold;
        border-width: 2px;
    }
    &:active {
        font-size: 16px;
        font-weight: bold;
        border-width: 2px;
        background-color: ${colorSecundario};
    }
`
const StyledOpciones=styled.div`
    display: flex;
    flex-direction:column;
    align-items: center;
    margin-bottom: 80px;
`

const StyledTitulo=styled.h2`
    color: ${colorPrimario};
`
const StyledTarjeta=styled.img`
    display: flex;
    width: 104px;
    height: 120px;
    justify-content: center;
`
const StyledTarjetaRectangular= styled.img`
    width: 240px;
    height: 80px;
`
const StyledContenido=styled.img`
    display: flex;
    width: 304px;
    justify-content: center;
`

const InstructivoOwner=()=>{
    const [opcionSeleccionada, setOpcionSeleccionada]=useState(null);

    const handleOpcionClick=(opcion)=>{
        setOpcionSeleccionada(opcion)
    }

    return(
        <section>
            <HeaderAuthenticatedOwner/>
            <StyledContenedor>
                <h2>Instructivo:</h2>
                <StyledBoton onClick={()=>handleOpcionClick("opcion 1")} color={colorPrimario}>1. Registra tus propiedades</StyledBoton>
                <StyledBoton onClick={()=>handleOpcionClick("opcion 2")} color="#59D5E0">2. Registra tus habitaciones</StyledBoton>
                <StyledBoton onClick={()=>handleOpcionClick("opcion 3")} color="#82CD47">3. Registra tus inquilinos</StyledBoton>
                <StyledBoton onClick={()=>handleOpcionClick("opcion 4")} color="#474747"> 4. Controla tus pagos</StyledBoton>

                {opcionSeleccionada&&(
                    <div>
                        {opcionSeleccionada==="opcion 1" &&(
                            <StyledOpciones>
                                <StyledTitulo>1. Registra tus propiedades</StyledTitulo>
                                <p>a) Presiona sobre la tarjeta de registra tus propiedades</p>
                                <StyledTarjeta src={tarjetaPropiedad1} alt="Imagen de instruccion del boton Registra tus propiedades"/>
                                <p>b) Presiona sobre la tarjeta de agregar una propiedad</p>
                                <StyledTarjetaRectangular src={agregarPropiedad2} alt="Imagen de instruccion del boton Agregar una propiedad"/>
                                <p>c) llena el formulario con los siguientes pasos:</p>
                                <StyledContenido src={formularioPropiedad3} alt="Imagen de instruccion de formulario para registrar una propiedad"/>
                                <StyledContenido src={formularioPropiedad4} alt="Imagen de instruccion de formulario para seleccionar ubicacion en mapa"/>
                                <p>d) como resultado se mostrara una tarjeta  de tu propiedad despues de haber llenado el formulario.</p>
                                <StyledContenido src={tarjetaPropiedadEditar4a} alt="Imagen de instruccion mostrando la propiedad registrada"/>
                            </StyledOpciones>
                        )
                        }
                        {opcionSeleccionada==="opcion 2" &&(
                            <StyledOpciones>
                                 <StyledTitulo>2. Registra tus habitaciones</StyledTitulo>
                                 <p>Ten en cuenta que para registrar habitaciones debes registrar antes una propiedad.</p>
                                 <p>a) Presiona sobre la tarjeta de registra tus habitaciones</p>
                                 <StyledTarjeta src={registraHabitaciones5} alt="Imagen de instruccion del boton Registra tus habitaciones"/>
                                 <p>b)Selecciona la propiedad en donde registraras tus habitaciones</p>
                                 <StyledTarjetaRectangular src={seleccionaPropiedad6} alt="Imagen de instruccion de desplegable para seleccionar propiedad"/>
                                 <p>c) Presiona sobre la tarjeta agregar una habitacion</p>
                                 <StyledTarjetaRectangular src={agregarHabitacion7} alt="Imagen de instruccion del boton Agregar una habitacion"/>
                                 <p>d) Llena el formulario con los siguientes pasos:</p>
                                 <StyledContenido src={formularioHabitacion8} alt="Imagen de instruccion de formulario para registrar una habitacion"/>
                                 <StyledContenido src={formularioHabitacion9} alt="Imagen de instruccion de formulario para registrar una habitacion"/>
                                 <p>e) como resultado se mostrara una tarjeta  de tu habitacion despues de haber llenado el formulario.</p>
                                 <StyledContenido src={tarjetaHabitacionEditar9a} alt="Imagen de instruccion mostrando la habitacion registrada"/>
                            </StyledOpciones>
                        )
                        }
                        {opcionSeleccionada==="opcion 3" &&(
                            <StyledOpciones>
                                 <StyledTitulo>3. Registra tus inquilinos</StyledTitulo>
                                 <p>a) Presiona sobre la tarjeta de registra tus inquilinos</p>
                                 <StyledTarjeta src={registraInquilinos10} alt="Imagen de instruccion del boton Registra tus inquilinos"/>
                                 <p>b) Sigue los pasos para registrar a tus inquilinos en cada una de las habitaciones de tu propiedad.</p>
                                 <StyledContenido src={formularioInquilinos11} alt="Imagen de instruccion para seleccionar una habitacion donde deseas registrar un inquilino"/>
                                 <p>c) Llena los datos de tu inquilino en la tarjeta de informacion.</p>
                                 <StyledContenido src={formularioBotonRegistrar11a} alt="Imagen de instruccion para registrar los datos de un inquilino en una habitacion"/>
                                 <p>d) Presiona el boton "Desocupar" y podras registrar a un nuevo inquilino. </p>
                                 <StyledContenido src={botonDesocupar12} alt="Imagen de instruccion para desocupar una habitacion"/>
                            </StyledOpciones>
                        )
                        }
                        {opcionSeleccionada==="opcion 4" &&(
                            <StyledOpciones>
                                 <StyledTitulo>4. Controla tus pagos</StyledTitulo>
                                 <p>a) Presiona sobre la tarjeta controla tus pagos</p>
                                 <StyledTarjeta src={controlaPagos13} alt="Imagen de instruccion del boton Controla tus pagos"/>
                                 <p>b) Selecciona una propiedad en el desplegable y luego elige una tarjeta de habitación para ver su información de pagos. En la tarjeta podrás ver fácilmente el tiempo de estadía en la barra y el estado de los pagos: si están al día o si hay algún monto pendiente.</p>
                                 <StyledContenido src={listaControlPagos14} alt="1.Selecciona la propiedad deseada en el desplegable 2.Selecciona la habitacion para revisar sus pagos"/>
                                 <p>c) Al seleccionar una habitación, se abrirá una ventana con su información y el estado de la deuda, donde podrás registrar el pago de cada mes de estadía.</p>
                                 <StyledContenido src={botonRegistrarPago15} alt="Imagen de instruccion para registrar un pago del inquilino"/>
                                 <p>d) Despues de haber presionado el boton anterior de "Registrar Pago". La tarjeta se actualizara de "debe" a "pagado".</p>
                                 <StyledContenido src={listaPagado16} alt="Imagen de instruccion de la tarjeta indicando que esta al dia en sus pagos"/>
                            </StyledOpciones>
                        )
                        }
                    </div>
                )
                }

            </StyledContenedor>
            <NavigationTab/>
        </section>
    )
}

export default InstructivoOwner