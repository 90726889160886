import styled from "styled-components";
import casaIcon from "../../assets/iconos/casa.svg";
import lupaIcon from "../../assets/iconos/lupa.svg";

const StyledDiv = styled.div`
  display: flex;
  margin-top: 24px;
`
const StyledDivBeneficio = styled.div`
  background-color: white;
  width: 144px;
  height: 136px;
  border-radius: 15px;
  text-align: center;
  margin: 4px 8px 4px 8px;
`;
const StyledIcon = styled.img`
  height: 48px;
  width: 48px;
  margin: 16px 16px 0px 16px;
`;
const Parrafo = styled.p`
  /* width: 240px; */
`;
const BeneficiosStudent = () => {
  return (
    <StyledDiv>
      <StyledDivBeneficio>
        <StyledIcon src={casaIcon} alt="Icono de casa"/>
        <Parrafo>Arkila habitaciones a tu medida</Parrafo>
      </StyledDivBeneficio>
      <StyledDivBeneficio>
        <StyledIcon src={lupaIcon} alt="Icono de lupa"/>
        <Parrafo>Habitaciones faciles de encontrar</Parrafo>
      </StyledDivBeneficio>
    </StyledDiv>
  );
};
export default BeneficiosStudent;
