import React from "react";
import "./App.css";
import GlobalStyle from "./GlobalStyle";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomeStudent from "./pages/Student/HomeStudent";
import Propiedades from "./pages/Student/Propiedades";
import Habitaciones from "./pages/Student/Habitaciones";
import RegistroStudent from "./pages/Student/RegistroStudent";
import HomeOwner from "./pages/Owner/HomeOwner";
import Contacto from "./pages/Owner/Contacto";
import SignIn from "./pages/SignIn";
import AgregarPropiedades from "./pages/Owner/AgregarPropiedades";
import RegistroPropiedad from "./pages/Owner/RegistroPropiedad";
import AgregarHabitaciones from "./pages/Owner/AgregarHabitaciones";
import RegistroHabitacion from "./pages/Owner/RegistroHabitacion";
import HomeStudentAuthenticated from "./pages/Student/HomeStudentAuthenticated";

import { AuthProviderContext } from "./context/AuthContext";
import { useAuth } from "./context/AuthContext";
import { UnauthenticatedContextProvider } from "./context/UnauthenticatedContext";

import PrivateRouteOwner from "./routers/PrivateRouteOwner";
import PrivateRouteStudent from "./routers/PrivateRouteStudent";
import PrivateRouteAdmin from "./routers/PrivateRouteAdmin";

import CompletarDatos from "./pages/Owner/CompletarDatos";
import HomeOwnerAuthenticated from "./pages/Owner/HomeOwnerAuthenticated";
import SuscripcionOwner from "./pages/Owner/SuscripcionOwner";
import InstructivoOwner from "./pages/Owner/InstructivoOwner";
import EditarPropiedad from "./pages/Owner/EditarPropiedad";
import EditarHabitacion from "./pages/Owner/EditarHabitacion";
import TabMapa from "./pages/Student/TabMapa";
import RegistrarInquilinos from "./pages/Owner/RegistrarInquilinos";
import ControlarPagos from "./pages/Owner/ControlarPagos";

import HomeAdmin from "./pages/Admin/HomeAdmin";
import GestionarSuscripciones from "./pages/Admin/GestionarSuscripciones";
import ResetPass from "./pages/ResetPass";
import Error404 from "./pages/Error404";
import RoleBasedProvider from "./context/RoleBasedProvider"
import BloquearPropietarios from "./pages/Admin/BloquearPropietarios";
import VerHabitacionesVencidas from "./pages/Admin/VerHabitacionesVencidas";
import TyC from "./pages/TyC";

function App() {
  return (
    <AuthProviderContext>
      <RoleBasedProvider>
        <UnauthenticatedContextProvider>
          <Router>
            <GlobalStyle />
            <AppRoutes />
          </Router>
        </UnauthenticatedContextProvider>
      </RoleBasedProvider>
    </AuthProviderContext>
  );
}

function AppRoutes() {
  const { userData, loading, usuarioActual } = useAuth();
  if (loading) {
    return <p>Loading...</p>; // o un componente de loading
  }
  return (
    <Routes>
      {/* Rutas accesibles para todos */}
      <Route path="/" element={<HomeStudent />} />
      <Route path="/Propiedades/:ubicacion" element={<Propiedades usuarioActual={usuarioActual} userData={userData}/>}/>
      <Route path="/TabMapa/:ubicacion" element={<TabMapa usuarioActual={usuarioActual} userData={userData}/>}/>
      <Route path="/RegistroStudent" element={<RegistroStudent />} />
      <Route path="/SignIn" element={<SignIn />} />
      <Route path="/ResetPass" element={<ResetPass />} />
      <Route path="/HomeOwner" element={<HomeOwner />} />
      <Route path="/TyC" element={<TyC />} />
      {!usuarioActual && <Route path="/Contacto" element={<Contacto />} />}
      <Route path="*" element={<Error404 />} />

      {/* Rutas específicas para estudiantes */}
      {userData.rol === "estudiante" && (
        <>
          <Route element={<PrivateRouteStudent />}>
            <Route path="/HomeStudentAuthenticated" element={<HomeStudentAuthenticated />} />
            <Route path="/TabMapa/:ubicacion" element={<TabMapa />} />
            <Route path="/TabMapa/:ubicacion/:latitudParam/:longitudParam" element={<TabMapa />} />
            <Route path="/Propiedades/:ubicacion" element={<Propiedades usuarioActual={usuarioActual} />} />
            <Route path="/Habitaciones/:idPropiedad" element={<Habitaciones />} />
          </Route>
        </>
      )}

      {/* Rutas específicas para propietarios */}
      {userData.rol === "propietario" && (
        <>
          <Route element={<PrivateRouteOwner />}>
            {usuarioActual && <Route path="/Contacto" element={<Contacto usuarioActual={usuarioActual} />} /> }
            <Route path="/CompletarDatos" element={<CompletarDatos />} />
            <Route path="/HomeOwnerAuthenticated" element={<HomeOwnerAuthenticated />} />
            <Route path="/SuscripcionOwner" element={<SuscripcionOwner />} />
            <Route path="/InstructivoOwner" element={<InstructivoOwner />} />
            <Route path="/AgregarPropiedades" element={<AgregarPropiedades />} />
            <Route path="/EditarPropiedad/:idPropiedad" element={<EditarPropiedad />} />
            <Route path="/AgregarHabitaciones" element={<AgregarHabitaciones />} />
            <Route path="/EditarHabitacion/:idPropiedad/:idHabitacion" element={<EditarHabitacion />} />
            <Route path="/RegistroPropiedad" element={<RegistroPropiedad />} />
            <Route path="/RegistroHabitacion/:idPropiedad" element={<RegistroHabitacion />} />
            <Route path="/RegistrarInquilinos" element={<RegistrarInquilinos />} />
            <Route path="/ControlarPagos" element={<ControlarPagos />} />
          </Route>
        </>
      )}

      {/* Rutas específicas para admins */}
      {userData.rol === "admin" && (
        <>
          <Route element={<PrivateRouteAdmin />}>
            <Route path="/HomeAdmin" element={<HomeAdmin />} />
            <Route path="/GestionarSuscripciones" element={<GestionarSuscripciones />} />
            <Route path="/BloquearPropietarios" element={<BloquearPropietarios />} />
            <Route path="/VerHabitacionesVencidas" element={<VerHabitacionesVencidas />} />
          </Route>
        </>
      )}
    </Routes>
  );
}

export default App;