import React, { useEffect } from 'react'
import HeaderAuthenticatedStudentAtras from "../../../components/HeaderAuthenticatedStudentAtras"
import styled from 'styled-components'
import { fondo } from '../../../components/UI/variables'
import Boton from "../../../components/Boton"
import ListaPropiedadesStudent from '../../../components/ListaPropiedadesStudent'
import { useNavigate, useParams } from 'react-router-dom'
import ListaPropiedadesUnauthenticated from '../../../components/ListaPropiedadesUnauthenticated'
import HeaderGeneral from '../../../components/HeaderGeneral'


const StyledDiv=styled.div`
  background-color: ${fondo};
  display:flex;
  flex-direction:column;
  align-items: center;
  padding-top:91px;
  padding-bottom:16px;
`
const StyledBotones=styled.div`
  display:flex;
  width: 100%;
  position: fixed;
`
const BotonLista=styled(Boton)`
  background-color: gray;
  width: 50%;
  height: 48px;
  border: 1px solid gray;
  color: white;
  font-weight: bold;
  border-radius:0;
  margin: 0;
`
const BotonMapa=styled(Boton)`
  background-color: ${fondo};
  width: 50%;
  height: 48px;
  border: 1px solid gray;
  color: gray;
  font-weight: bold;
  border-radius:0;
  margin: 0;
`

const Propiedades = ({usuarioActual, userData}) => {
  let {ubicacion}=useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica si la página se ha actualizado
    if (sessionStorage.getItem("recargado")) {
      sessionStorage.removeItem("recargado"); // Elimina la marca para evitar bucles
      navigate("/"); // Redirige solo si se refrescó la página
    }
    // Marcar la sesión como "recargada" al salir
    const handleBeforeUnload = () => {
      sessionStorage.setItem("recargado", "true");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  return (
    <>
      {usuarioActual && userData.rol==="estudiante" ? <HeaderAuthenticatedStudentAtras/> : <HeaderGeneral/> }
      <StyledDiv>
        <StyledBotones>
          <BotonLista to={`/Propiedades/${ubicacion}`}>🗒️ Ver Lista</BotonLista>
          <BotonMapa to={`/TabMapa/${ubicacion}`}>🌎 Ver Mapa</BotonMapa>
        </StyledBotones>
        {usuarioActual && userData.rol==="estudiante" ? <ListaPropiedadesStudent/> : <ListaPropiedadesUnauthenticated/>}
      </StyledDiv>
    </>
  )
}

export default Propiedades