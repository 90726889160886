import React from 'react'
import styled from 'styled-components'
import { fondo } from '../../../components/UI/variables'
import FormStudent from "../../../components/FormStudent"
import Titulo2 from "../../../components/Titulo2"

const StyledSection = styled.section`
  background-color: ${fondo};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`
const StyledTitulo = styled(Titulo2)`
  margin: 0px 16px 50px 16px;
`
const RegistroStudent = () => {
  return (
    <StyledSection >
      <StyledTitulo $naranja>Registrate como estudiante es totalmente gratis!! 😮 </StyledTitulo>
      <FormStudent/>
    </StyledSection>
  )
}

export default RegistroStudent