import CardMenuOwner from "../../../components/CardMenuOwner"
import styled from "styled-components"
import HeaderAuthenticatedOwner from "../../../components/HeaderAuthenticatedOwner"
import IconCuadernoPersonas from "../../../assets/iconos/cuadernoPersonas.svg"
import IconMoneda from "../../../assets/iconos/moneda.svg"
import IconPropiedades from "../../../assets/iconos/propiedades.svg"
import IconHabitaciones from "../../../assets/iconos/habitaciones.svg"
import { fondo } from "../../../components/UI/variables"
import NavigationTab from "../../../components/NavigationTab"

const StyledSection = styled.div`
  background-color: ${fondo};
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledDiv = styled.div`
  display: flex;
  height: calc(100% - 72px - 174px);
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`
const StyledP = styled.div`
  width: 300px;
  text-align: center;
  margin: 112px 0px 24px 0px;
  font-size: 16px;
`
const HomeOwnerAuthenticated = ()=>{
    return(
        <StyledSection>
            <HeaderAuthenticatedOwner/>
            <StyledP>Bienvenido, inicia publicando tus propiedades y habitaciones.</StyledP>
            <StyledDiv>
                <CardMenuOwner to="/AgregarPropiedades">
                    <img src={IconPropiedades} alt="Publica tu propiedad"/>
                    1. Publica tu propiedad
                </CardMenuOwner>
                <CardMenuOwner to="/AgregarHabitaciones">
                    <img src={IconHabitaciones} alt="Publica tus habitaciones"/>
                    2. Publica tus habitaciones
                </CardMenuOwner>
                <CardMenuOwner to="/RegistrarInquilinos">
                    <img src={IconCuadernoPersonas} alt="Controla tus inquilinos"/>
                    3. Controla tus inquilinos
                </CardMenuOwner>
                <CardMenuOwner to="/ControlarPagos">
                    <img src={IconMoneda} alt="Controla tus pagos"/>        
                    4. Controla tus cobros
                </CardMenuOwner>
            </StyledDiv>
            <NavigationTab/>
        </StyledSection>
    )
}

export default HomeOwnerAuthenticated