import React, { useEffect, useState, useContext } from "react";
import { UnauthenticatedContextGeneral } from "../../context/UnauthenticatedContext";
import styled from "styled-components";
import CardPropiedadUnauthenticated from "../CardPropiedadUnauthenticated";

const StyledSection = styled.section`
  margin-top: 48px;
`;
const ListaPropiedadesUnauthenticated = () => {
  const { propiedadesUnauthenticated } = useContext(UnauthenticatedContextGeneral);
  const [lista, setLista] = useState([]);
  const propiedades = propiedadesUnauthenticated;

  useEffect(() => {
    setLista(propiedades);
  }, [propiedades]);

  return (
    <StyledSection>
      {lista.map((list, i) => {
        if(propiedades && list.suscripcionActiva==true && list.disponibilidadPropiedad==true){
          return (
            <CardPropiedadUnauthenticated
              key={i}
              imagen={list.imagen}
              direccion={list.direccion}
              descripcion={list.descripcion}
              idPropiedad={list.idPropiedad}
              latitud={list.latitud}
              longitud={list.longitud}
            />
          );
        }
      })}
    </StyledSection>
  );
};

export default ListaPropiedadesUnauthenticated;
