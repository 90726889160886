import React, { useEffect, useState, useContext } from "react";
import CardPropiedadAuthenticated from "../CardPropiedadAuthenticated";
import { AuthContextStudent } from "../../context/AuthStudent";
import styled from "styled-components";

const StyledSection = styled.section`
  margin-top: 48px;
`;
const ListaPropiedadesStudent = () => {
  const { currentUser } = useContext(AuthContextStudent);
  const [lista, setLista] = useState([]);
  const propiedades = currentUser[1];

  useEffect(() => {
    setLista(propiedades);
  }, [propiedades]);

  return (
    <StyledSection>
      {lista.map((list, i) => {
        let precioMinimo = Math.min(...list.preciosHabitaciones);
        let precioMaximo = Math.max(...list.preciosHabitaciones);

        if(currentUser && list.suscripcionActiva==true && list.disponibilidadPropiedad==true){
          return (
            <CardPropiedadAuthenticated
              key={i}
              imagen={list.imagen}
              direccion={list.direccion}
              descripcion={list.descripcion}
              idPropiedad={list.idPropiedad}
              latitud={list.latitud}
              longitud={list.longitud}
              precioMinimo={precioMinimo}
              precioMaximo={precioMaximo}
            />
          );
        }
      })}
    </StyledSection>
  );
};

export default ListaPropiedadesStudent;
