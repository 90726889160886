import styled from "styled-components";
import Titulo2 from "../Titulo2";
import { useEffect, useState } from "react";
import { Link} from "react-router-dom";

const LinkStyled = styled(Link)`
text-decoration: none;
`
const Direccion = styled(Titulo2)`
  height: 32px;
  width: 152px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DivPrecio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 152px;
  margin: 0;
`;
const PrecioLetras = styled.p`
  font-size: 10px;
  font-weight: 500;
  margin: 0;
`;
const PrecioNumeros = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;
const StyledImg = styled.img`
  width: 152px;
  height: 144px;
`;
const StyledP = styled.p`
  color: green;
  margin: 4px;
  font-weight: bold;
  text-decoration: underline;
`;

const CardPropiedadMapaUnauthenticated = (props) => {

    return (
        <section>
            <LinkStyled to="/RegistroStudent">
              <Direccion>{props.direccion}</Direccion>
              <StyledImg src={props.urlImagen} alt="Fografia de la propiedad"/>
            </LinkStyled>
        </section>
    );
};

export default CardPropiedadMapaUnauthenticated;
