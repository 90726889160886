import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { colorSecundario, fondo } from '../../components/UI/variables'
import Mostrar from "../../assets/iconos/Mostrar.svg"
import Ocultar from "../../assets/iconos/Ocultar.svg"

import Titulo2 from "../../components/Titulo2"
import Etiqueta from "../../components/Etiqueta"
import Entrada from "../../components/Entrada"
import TextoEnlace from "../../components/TextoEnlace"
import appFirebase from "../../firebase"
import { getAuth,sendEmailVerification,signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import BotonSubmit from '../../components/BotonSubmit'
import { useAuth } from '../../context/AuthContext'

const StyledSection = styled.section`
  background-color: ${fondo};
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 56px 0px;
`
const StyledDiv= styled.div`
  border: 6px solid ${colorSecundario};
  padding: 24px;
  border-radius: 8px;
`
const StyledTituloR = styled(Titulo2)`
  margin: 0px 0px 0px 0px;
  color: black;
`
const StyledTituloI = styled(Titulo2)`
  margin: 72px 0px 16px 0px;
`
const StyledEtiqueta = styled(Etiqueta)`
  display: block;
  text-align: left;
  margin-bottom: 16px;
`
const StyledPError = styled.p`
  color: white;
  height: 32px;
  width: auto;
  font-size: 14px;
  margin: 0 16px 24px 16px;
  background-color: #e84c3d;
  padding: 16px 16px 16px 16px;
  border-radius: 4px;
  text-align: center;
`
const StyledImg = styled.img`
  position: absolute;
  transform: translate(1250%,20%);
`
const StyledLink = styled(Link)`
  margin-top: 32px;
  color: blue;
  font-weight: 500;
`
const StyledP = styled.p`
  margin: 24px 0 0 0;
`

const SignIn = () => {
  const auth = getAuth(appFirebase);
  const { userData, usuarioActual } = useAuth();
  const navigate=useNavigate()
  const [showPass, setShowPass] = useState(false);
  const [correo, setCorreo]=useState("")
  const [password, setPasword]=useState("")
  const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);
  const [errorCorreoContrasena, setErrorCorreoContrasena] = useState(false)

  const manejarCorreo=(e)=>{
    setCorreo(e.target.value)
  }
  const manejarPassword=(e)=>{
    setPasword(e.target.value)
  }

  //Funcion para cuando un usuario intenta iniciar sesion
  const manejarLogin=useCallback(
    async event =>{
      event.preventDefault()
      if(!correo || !password){
        alert("por favor complete todos los campos.")
        return; // Salir de la función si algún campo está vacío (finaliza el proceso de logueo)
      }
      setProcesoEnvioIniciado(true);
      try{
        await signInWithEmailAndPassword(auth, correo, password)
        if(!auth.currentUser.emailVerified){
          const configuracion = {
            url: process.env.NODE_ENV === "production"
              ? "https://www.arkilados.com/" // URL en producción
              : "http://localhost:3000"      // URL en desarrollo
          };
          await sendEmailVerification(auth.currentUser, configuracion);
          await signOut(auth)
          alert("Verifica tu correo electronico, antes de iniciar sesion")
          navigate("/SignIn") //Especificamos a donde nos llevara si no estamos verificados
        }else if(userData.bloqueado){
          await signOut(auth)
          alert("Tu cuenta ha sido bloqueada por incumplimiento de los terminos y condiciones")
          navigate("/SignIn") //Especificamos a donde nos llevara si estamos bloqueados
        }else if(auth.currentUser.emailVerified && userData.rol==="propietario" && userData.fechaInicioSuscripcion==null){
          await signOut(auth)
          navigate("/Contacto") //Especificamos a donde nos llevara si estamos verificados pero no has sido activado
        }else{
          //Especificamos a donde nos llevara cuando iniciemos sesion
          if(usuarioActual && userData.rol==="estudiante" && userData.bloqueado==false){
              navigate("/HomeStudentAuthenticated")
            }else if(usuarioActual && userData.rol==="propietario" && userData.fechaInicioSuscripcion && userData.dni && userData.bloqueado==false){
              navigate("/HomeOwnerAuthenticated")
            }else if(usuarioActual && userData.rol==="propietario" && userData.fechaInicioSuscripcion && userData.dni==="" &&userData.bloqueado==false){
              navigate("/CompletarDatos")
            }else if(usuarioActual && userData.rol==="admin" && userData.bloqueado==false){
              navigate("/HomeAdmin")
            }
        }
      } catch(error){
        setErrorCorreoContrasena(true)
        setProcesoEnvioIniciado(false)
        console.log(error)
      }
    }, [navigate, correo, password]
  )

  //Especificamos a donde nos llevara si ya existe una sesion iniciada al intentar entrar a la pagina de SignIn
  if(usuarioActual && usuarioActual.emailVerified && userData.rol=="estudiante" && userData.bloqueado==false){ 
    return <Navigate to="/HomeStudentAuthenticated"/> //nos lleva al HomeStudentAuthenticated si eres estudiante
  }else if(usuarioActual && usuarioActual.emailVerified && userData.rol=="propietario" && userData.fechaInicioSuscripcion && userData.dni && userData.bloqueado==false){
    return <Navigate to="/HomeOwnerAuthenticated"/> //nos lleva al HomeOwnerAuthenticated si eres propietario y en algun momento has sido activado (has pagado una suscripcion)
  }else if(usuarioActual && usuarioActual.emailVerified && userData.rol==="propietario" && userData.fechaInicioSuscripcion && userData.dni==="" && userData.bloqueado==false){
    return <Navigate to="/CompletarDatos"/> //nos lleva al CompletarDatos si eres un nuevo propietario por lo que no tienes tus datos completos
  }else if(usuarioActual && usuarioActual.emailVerified && userData.rol=="admin" && userData.bloqueado==false){
    return <Navigate to="/HomeAdmin" /> //nos lleva al HomeAdmin si eres un admin
  }else if(usuarioActual && usuarioActual.emailVerified && auth.currentUser.emailVerified && userData.rol==="propietario" && userData.fechaInicioSuscripcion==null && userData.bloqueado==false){
    return <Navigate to="/Contacto"/> //nos lleva al Contacto si eres un propietario verificado y nunca has sido activado
  }

  return (
    <StyledSection>
      <StyledDiv>
        <StyledTituloR $naranja>Registrate en Arkilados</StyledTituloR>
        <StyledP>¿No tienes Cuenta y eres Estudiante?</StyledP>
        <TextoEnlace to="/RegistroStudent">Registrate aqui</TextoEnlace>
        <StyledP>¿No tienes Cuenta y eres Propietario?</StyledP>
        <TextoEnlace to="/HomeOwner">Registrate aqui</TextoEnlace>
      </StyledDiv>
      <StyledTituloI $naranja>¿Ya tienes una cuenta? Inicia Sesion</StyledTituloI>
        {errorCorreoContrasena?<StyledPError>Error: El correo o la contraseña que has introducido no son correctos</StyledPError>:<></>}
      <form onSubmit={manejarLogin}>
        <StyledEtiqueta>Correo:
          <Entrada onChange={manejarCorreo} value={correo} type='email'/>
        </StyledEtiqueta>
        <StyledEtiqueta>Contraseña:
          <div onClick={()=>setShowPass(!showPass)}>
            {showPass ? <StyledImg src={Mostrar} alt='Mostrar contraseña'/> : <StyledImg src={Ocultar} alt='Ocultar Contraseña'/>}
          </div>
          <Entrada onChange={manejarPassword} value={password} type={showPass ? "text" : "password"}/>
        </StyledEtiqueta>
        <BotonSubmit $large  to="/HomeStudentAuthenticated" $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>{procesoEnvioIniciado ? "Iniciando... ⌛⌛" : "Iniciar Sesión"}</BotonSubmit>
      </form>
      <StyledLink to="/ResetPass">¿Olvidaste tu contraseña?</StyledLink>
    </StyledSection>
  )
}

export default SignIn