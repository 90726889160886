import React from 'react'
import HeaderAuthenticatedStudent from "../../../components/HeaderAuthenticatedStudent"
import HeaderGeneral from '../../../components/HeaderGeneral'
import styled from 'styled-components'
import { fondo } from '../../../components/UI/variables'
import MapaStudent from '../../../components/MapaStudent'
import MapaStudentUnauthenticated from '../../../components/MapaStudentUnauthenticated'
import Boton from "../../../components/Boton"
import { useParams } from 'react-router-dom'


const StyledDiv=styled.div`
  background-color: ${fondo};
  display:flex;
  flex-direction:column;
  align-items: center;
  padding-top:88px;
  padding-bottom:16px;
`
const StyledBotones=styled.div`
  display:flex;
  width: 100%;
  position: fixed;
`
const BotonLista=styled(Boton)`
  background-color: ${fondo};
  width: 50%;
  height: 48px;
  border: 1px solid gray;
  color: gray;
  font-weight: bold;
  border-radius:0;
  margin: 0;
`
const BotonMapa=styled(Boton)`
  background-color: gray;
  width: 50%;
  height: 48px;
  border: 1px solid gray;
  color: white;
  font-weight: bold;
  border-radius:0;
  margin: 0;
`

const TabMapa = ({usuarioActual, userData}) => {
  let {ubicacion}=useParams();
  return (
    <>
      {usuarioActual && userData.rol==="estudiante" ? <HeaderAuthenticatedStudent/> : <HeaderGeneral/> }
      <StyledDiv>
        <StyledBotones>
          <BotonLista to={`/Propiedades/${ubicacion}`}>🗒️ Ver Lista</BotonLista>
          <BotonMapa to={`/TabMapa/${ubicacion}`}>🌎 Ver Mapa</BotonMapa>
        </StyledBotones>
        {usuarioActual && userData.rol==="estudiante"? <MapaStudent/> : <MapaStudentUnauthenticated/> }
      </StyledDiv>
    </>
  )
}

export default TabMapa