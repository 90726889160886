import styled from "styled-components";
import { colorPrimario, colorSecundario, fondo, textoOscuro } from "../UI/variables";
import Etiqueta from "../Etiqueta";
import Boton from "../Boton"
import flechaIzquierda from "../../assets/iconos/flechaIzquierda.svg"

import { useNavigate } from "react-router-dom";
import BotonSubmit from "../BotonSubmit";

import appFirebase from "../../firebase";
import { getAuth, signOut } from "firebase/auth";
import { useContext } from "react";
import { AuthContextStudent } from "../../context/AuthStudent";

const auth = getAuth(appFirebase);

//StyledDiv se refiere al encabezado general
const StyledHeader = styled.header`
  background-color: ${fondo};
  width: 100vw;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 8px;
  border-bottom: 3px solid ${colorSecundario};
  position: fixed;
  z-index: 1000;
`;

const StyledPerfil = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

//la etiqueta es un label ojo.
const EtiquetaNombre = styled(Etiqueta)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: black;
  margin-top: 16px;
  justify-content: center;
`;
const BotonCerrarSesion = styled(BotonSubmit)`
  background-color: ${fondo};
  border: 1.5px solid ${colorPrimario};
  color: ${textoOscuro};
  font-weight: 500;
  
`;

const HeaderAuthenticatedStudentAtras = (props) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContextStudent);

  const datosEstudiante = currentUser[0];

  const cerrarSesion = ()=>{
    signOut(auth)
    navigate("/")
  }

  return (
    <StyledHeader>
        {props.urlAtras ? 
        <Boton to={props.urlAtras}>
          <img src={flechaIzquierda} alt="Atras"/>
          Atras
        </Boton> : 
        <Boton to= "/HomeStudentAuthenticated">
          <img src={flechaIzquierda} alt="Atras"/>
          Atras
        </Boton> 
        } 
      <StyledPerfil>
        <EtiquetaNombre>{datosEstudiante.nombre}</EtiquetaNombre>
        <BotonCerrarSesion onClick={cerrarSesion}>Cerrar Sesión</BotonCerrarSesion>
      </StyledPerfil>
    </StyledHeader>
  );
};

export default HeaderAuthenticatedStudentAtras;